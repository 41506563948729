import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import person1 from '../../assets/person1.png'
import person2 from '../../assets/person2.png'
import person3 from '../../assets/person3.png'
import person4 from '../../assets/person4.png'
import person5 from '../../assets/person5.png'
import person6 from '../../assets/person6.png'
import person7 from '../../assets/person7.png'
import person8 from '../../assets/person8.png'
import { Fonts } from '../../utils/Fonts'
import { useMediaQuery } from 'react-responsive'

const TeamSection = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const teamMembers = [
    {
      name: 'DR. Amin Zargar, PhD',
      title: 'Chief Executive Officer',
      description:
        'Amin is the CEO and co-founder of ResVita Bio, recognized for his leadership in chemical engineering, bioengineering, and synthetic biology. Awarded the NIH Ruth L. Kirschstein Fellowship and the UC Berkeley Bakar Entrepreneurship Fellowship, he has also served as Principal Investigator on four NIH-funded projects.',
      img: person2
    },
    {
      name: 'DR. Jay Keasling, PhD',
      title: 'Co-Founder & Board Member',
      description:
        'Jay, co-founder of ResVita Bio, is a professor at UC Berkeley and a pioneering figure in synthetic biology. Named Discover Magazine’s Scientist of the Year, Jay led the development of artemisinin, the first synthetic biology-produced, FDA-approved drug, which revolutionized malaria treatment. His research in metabolic engineering underpins ResVita’s therapeutic platform.',
      img: person1
    },
    { name: 'Emilie Rennie', title: 'PhD, Chief of Staff', img: person6 },
    { name: 'Andrew Hagen', title: 'PhD, Principal Scientist II', img: person3 },
    { name: 'Shyam Sah', title: 'PhD, Senior Scientist II', img: person4 },
    { name: 'Sarah Moradi Fard', title: 'PhD, Senior Scientist I', img: person7 },
    { name: ' Robyn Jong', title: ' PhD, Senior Scientist I', img: person8 }
  ]

  const styles = {
    container: { textAlign: 'center', marginTop: '100px', marginBottom: '100px' },
    heading: {
      ...Fonts.Inter,
      fontSize: isMobile ? '30px' : '48px',
      fontWeight: 700,
      textAlign: 'left'
    },
    description: {
      marginTop: '10px',
      marginBottom: '30px',
      ...Fonts.Inter,
      fontSize: isMobile ? '15px' : '24px',
      fontWeight: 400,
      textAlign: 'left'
    },
    card: { overflow: 'hidden', borderRadius: '0', border: 'none' },
    imgContainer: {
      position: 'relative',
      overflow: 'hidden',
      height: isMobile ? '220px' : '350px'
    },
    imgContainerBottom: {
      position: 'relative',
      overflow: 'hidden',
      height: isMobile ? '220px' : '300px'
    },
    img: { width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.3s ease' },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#005F99',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      transition: 'opacity 0.3s ease',
      padding: isMobile ? '10px' : '15px',
      textAlign: 'center'
    },
    overlayText: {
      fontSize: isMobile ? '14px' : '20px',
      fontWeight: 600,
      textDecorationLine: 'underline'
    },
    title: { fontWeight: '500', fontSize: isMobile ? '12px' : '16px' },
    descriptionText: {
      ...Fonts.Inter,
      fontSize: isMobile ? '10px' : '14px',
      fontWeight: 400,
      textAlign: 'justify',
      marginTop: '8px'
    }
  }

  return (
    <Container style={styles.container} id="team">
      <Row className="mt-4 align-items-center">
        {/* Heading and description */}
        <Col sm={12} md={12} lg={6} className="d-flex flex-column">
          <p style={{ ...styles.heading, textAlign: isMobile ? 'center' : 'left' }}>
            Meet Our Team
          </p>
          <p style={{ ...styles.description, textAlign: isMobile ? 'center' : 'left' }}>
            The scientists pioneering breakthroughs at the intersection of synthetic biology and
            immunology.
          </p>
        </Col>

        {/* Top 2 images */}
        <Col sm={12} md={12} lg={6}>
          <Row>
            {teamMembers.slice(0, 2).map((member, index) => (
              <Col
                key={index}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{ marginBottom: isMobile ? '-10px' : '3px' }}
              >
                <Card style={styles.card}>
                  <div
                    style={styles.imgContainer}
                    onMouseEnter={(e) => (e.currentTarget.children[1].style.opacity = 1)}
                    onMouseLeave={(e) => (e.currentTarget.children[1].style.opacity = 0)}
                  >
                    <Card.Img variant="top" src={member.img} alt={member.name} style={styles.img} />
                    <div style={styles.overlay}>
                      <div style={styles.overlayText}>{member.name}</div>
                      <div style={styles.title}>{member.title}</div>
                      {member.description && (
                        <div style={styles.descriptionText}>{member.description}</div>
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* Bottom 5 images */}
<Row className="mt-4 px-0"
  style={{ 
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: isMobile ? 'center' : 'space-between', 
    gap: isMobile ? '5px' : '0px' // Reduced gap
  }}
>
  {teamMembers.slice(2, 7).map((member, index) => (
    <Col 
      key={index} 
      xs={6} sm={6} md={4} lg={2} 
      className="mb-2 d-flex justify-content-center"
      style={{
        flex: isMobile ? '1 1 49%' : '1 1 calc(20% - 10px)', // Adjust width for even spacing
        maxWidth: isMobile ? '49%' : '20%'
      }}
    >
      <Card style={{ ...styles.card, width: '100%' }}>
        <div
          style={{
            ...styles.imgContainer, // Ensure same styling as top images
            height: isMobile ? styles.imgContainer.height : styles.imgContainerBottom.height
          }}
          onMouseEnter={(e) => (e.currentTarget.children[1].style.opacity = 1)}
          onMouseLeave={(e) => (e.currentTarget.children[1].style.opacity = 0)}
        >
          <Card.Img variant="top" src={member.img} alt={member.name} style={styles.img} />
          <div style={styles.overlay}>
            <div style={styles.overlayText}>{member.name}</div>
            <div style={styles.title}>{member.title}</div>
          </div>
        </div>
      </Card>
    </Col>
  ))}
</Row>

    </Container>
  )
}

export default TeamSection
