import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import skin from "../../assets/skin.gif";
import { Fonts } from "../../utils/Fonts";
import Color from "../../utils/Color";
import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div style={styles.container} id="aboutUs">
      <h2 style={{ ...styles.heading, fontSize: isMobile ? "30px" : "48px" }}>
        About Us
      </h2>
      <div style={styles.divider}></div>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={5} md={6} lg={5} className="text-center">
            <img
              src={skin}
              alt="Skin Layer Illustration"
              style={styles.image}
            />
          </Col>
          <Col xs={12} sm={7} md={6} lg={5} style={styles.textContainer}>
            <h3
              style={{
                ...styles.subHeading,
                fontSize: isMobile ? "24px" : "32px",
              }}
            >
              Transforming Skin Disease Treatment with Synthetic Biology
            </h3>
            <p
              style={{
                ...styles.paragraph,
                fontSize: isMobile ? "15px" : "20px",
              }}
            >
              ResVita Bio is a therapeutics company dedicated to developing
              life-changing treatments for skin diseases. Our proprietary
              platform leverages engineered bacteria to deliver therapeutic
              proteins directly to affected areas, providing a continuous,
              sustained treatment approach. This continuous protein therapy
              combines the specificity and functionality of biologics with the
              topical convenience of small molecules—while avoiding the systemic
              safety concerns associated with both. We are committed to
              transforming care for conditions such as Netherton Syndrome and
              Atopic Dermatitis.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const styles = {
  container: {
    padding: "50px 15px", // Added padding for smaller screens
    textAlign: "center",
    color: "#333",
  },
  heading: {
    fontSize: "48px", // Responsive font size
    fontWeight: 700,
    ...Fonts.Inter,
    color: Color.black,
    marginTop: "30px",
  },
  divider: {
    width: "100%",
    height: "1px",
    // backgroundColor: "#66CCFF",
    margin: "10px auto 30px",
  },
  image: {
    width: "100%",
    maxWidth: "487px",
    height: "auto",
    objectFit: "cover",
  },
  textContainer: {
    maxWidth: "600px",
    textAlign: "left",
    margin: "0 auto", // Center align for smaller screens
  },
  subHeading: {
    fontSize: "32px", // Responsive font size
    fontWeight: 700,
    ...Fonts.Inter,
    backgroundImage: "linear-gradient(to right, #005F99 , #66CCFF)",
    backgroundClip: "text",
    color: "transparent",
  },
  paragraph: {
    fontSize: "20px", // Responsive font size
    fontWeight: 400,
    ...Fonts.Inter,
    color: "black",
    textAlign: "justify",
    paddingTop: "10px",
  },
};

export default AboutUs;
